import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Table } from "antd";
import { useEffect, useState } from "react";

import { additionalColors } from "constants/colors";
import {
    SearchPlanFormCountryConstraint,
    SearchPlanFormCountyConstraint,
    SearchPlanFormDisplayStartDateConstraint,
    SearchPlanFormIndicatorTagConstraint,
    SearchPlanFormInitiativeTypeConstraint,
    SearchPlanFormMGDConstraint,
    SearchPlanFormOwnerConstraint,
    SearchPlanFormOwnerTypeConstraint,
    SearchPlanFormPopulationConstraint,
    SearchPlanFormProjectBudgetConstraint,
    SearchPlanFormSourceTimeframeConstraint,
    SearchPlanFormStateConstraint,
    SearchPlanFormTimingStatusConstraint,
} from "reactQuery/hooks/apiTypes";
import { useGetIntelligenceScopeDetail } from "reactQuery/hooks/useSearchPlanForm";

import {
    budgetMaximumColumn,
    budgetMinimumColumn,
    copyColumn,
    countiesColumn,
    countriesColumn,
    deleteColumn,
    filterTypeColumn,
    indicatorTagsColumn,
    initiativeTypesColumn,
    maximumMGDColumn,
    maximumMonthsColumn,
    minimumMGDColumn,
    nullColumn,
    numberColumn,
    ownersColumn,
    ownerTypesColumn,
    populationMaximumColumn,
    populationMinimumColumn,
    requirementTypeColumn,
    startDisplayDateColumn,
    statesColumn,
    timingStatusesColumn,
    profilesColumn,
} from "../columnInfo";

const { Panel } = Collapse;

type ConstraintType =
    | "countries"
    | "states"
    | "counties"
    | "owners"
    | "owner_types"
    | "population"
    | "initiative_types"
    | "timing_status"
    | "indicator_tags"
    | "project_budget"
    | "source_document_date"
    | "design_flow_mgd"
    | "display_start_date";
interface SearchPlanFormConstraint
    extends SearchPlanFormCountryConstraint,
        SearchPlanFormStateConstraint,
        SearchPlanFormCountyConstraint,
        SearchPlanFormIndicatorTagConstraint,
        SearchPlanFormInitiativeTypeConstraint,
        SearchPlanFormMGDConstraint,
        SearchPlanFormOwnerConstraint,
        SearchPlanFormOwnerTypeConstraint,
        SearchPlanFormPopulationConstraint,
        SearchPlanFormProjectBudgetConstraint,
        SearchPlanFormSourceTimeframeConstraint,
        SearchPlanFormTimingStatusConstraint,
        SearchPlanFormDisplayStartDateConstraint {}

const ConstraintRow = ({
    constraintType,
    intelligenceScopeId,
}: {
    constraintType: ConstraintType;
    intelligenceScopeId: number;
}) => {
    const intelligenceScopeDetailQuery =
        useGetIntelligenceScopeDetail(intelligenceScopeId);

    const [dataSource, setDataSource] = useState([]);

    const columns = [
        numberColumn,
        requirementTypeColumn,
        filterTypeColumn,
        profilesColumn,
        nullColumn,
        copyColumn,
        deleteColumn,
    ];

    let specialColumns = [],
        constraintKey: string,
        panelHeader: string,
        panelKey: string;
    switch (constraintType) {
        case "countries":
            specialColumns = [countriesColumn];
            constraintKey = "country_constraints";
            panelHeader = "Countries";
            panelKey = "countries";
            break;
        case "states":
            specialColumns = [statesColumn];
            constraintKey = "state_constraints";
            panelHeader = "States";
            panelKey = "states";
            break;
        case "counties":
            specialColumns = [countiesColumn];
            constraintKey = "county_constraints";
            panelHeader = "Counties";
            panelKey = "counties";
            break;
        case "owners":
            specialColumns = [ownersColumn];
            constraintKey = "owner_constraints";
            panelHeader = "Owners";
            panelKey = "owners";
            break;
        case "owner_types":
            specialColumns = [ownerTypesColumn];
            constraintKey = "owner_type_constraints";
            panelHeader = "Owner Types";
            panelKey = "owner_types";
            break;
        case "population":
            specialColumns = [populationMinimumColumn, populationMaximumColumn];
            constraintKey = "population_constraints";
            panelHeader = "Population";
            panelKey = "population";
            break;
        case "initiative_types":
            specialColumns = [initiativeTypesColumn];
            constraintKey = "initiativetype_constraints";
            panelHeader = "Initiative Types";
            panelKey = "initiative_types";
            break;
        case "timing_status":
            specialColumns = [timingStatusesColumn];
            constraintKey = "timing_status_constraints";
            panelHeader = "Timing Status";
            panelKey = "timing_status";
            break;
        case "indicator_tags":
            specialColumns = [indicatorTagsColumn];
            constraintKey = "indicatortag_constraints";
            panelHeader = "Indicator Tags";
            panelKey = "indicator_tags";
            break;
        case "project_budget":
            specialColumns = [budgetMinimumColumn, budgetMaximumColumn];
            constraintKey = "project_budget_constraints";
            panelHeader = "Project Budget";
            panelKey = "project_budget";
            break;
        case "source_document_date":
            specialColumns = [maximumMonthsColumn];
            constraintKey = "source_timeframe_constraints";
            panelHeader = "Source Document Date";
            panelKey = "source_document_date";
            break;
        case "design_flow_mgd":
            specialColumns = [minimumMGDColumn, maximumMGDColumn];
            constraintKey = "mgd_constraints";
            panelHeader = "Design Flow MGD";
            panelKey = "design_flow_mgd";
            break;
        case "display_start_date":
            specialColumns = [startDisplayDateColumn];
            constraintKey = "display_start_date_constraints";
            panelHeader = "Display Date";
            panelKey = "display_date";
            break;
        default:
            break;
    }

    columns.splice(1, 0, ...specialColumns);

    useEffect(() => {
        if (intelligenceScopeDetailQuery.isSuccess) {
            const constraints = intelligenceScopeDetailQuery.data[constraintKey];
            setDataSource(
                constraints.map(
                    (constraint: SearchPlanFormConstraint, index: number) => {
                        let number = index + 1;
                        return {
                            number,
                            key: number,
                            constraintId: constraint.id,
                        };
                    }
                )
            );
        }
    }, [intelligenceScopeDetailQuery.isSuccess]);

    const addRow = () => {
        let number = dataSource.length + 1;
        const newData = {
            number,
            key: number,
            constraintId: 0,
        };
        setDataSource([...dataSource, newData]);
    };

    return (
        <Collapse>
            <Panel header={panelHeader} key={panelKey}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                    pagination={false}
                />

                <Button
                    type="dashed"
                    style={{ width: "100%", color: additionalColors.green500 }}
                    icon={<PlusOutlined />}
                    onClick={() => addRow()}
                >
                    Add row
                </Button>
            </Panel>
        </Collapse>
    );
};

export default ConstraintRow;
