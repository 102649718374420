import { Form, Select } from "antd";
import { useState } from "react";

import { useSearchDebounced } from "components/helpers/debounce";
import { Owner } from "reactQuery/hooks/apiTypes";
import { useGetOwnersByQuery } from "reactQuery/hooks/useOwners";

const OwnersSelect = ({
    constraintId,
    number,
}: {
    constraintId: number;
    number: number;
}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const queryMinChars = 1;
    const enterQueryPrompt = `Enter at least ${queryMinChars} characters to search for an owner`;

    const ownersQueryEnabled = searchQuery.length >= queryMinChars;
    const ownersQuery = useGetOwnersByQuery(searchQuery, ownersQueryEnabled);
    const debounceSearch = useSearchDebounced(setSearchQuery);

    return (
        <Form.Item
            style={{ margin: 0 }}
            name={`constraint_${constraintId}_owners_${number}`}
        >
            <Select
                placeholder="Select one or more owners"
                showSearch
                filterOption={false}
                allowClear={true}
                mode="multiple"
                style={{ width: "100%" }}
                onSearch={(value) => {
                    debounceSearch(value);
                }}
                onClear={() => {
                    debounceSearch("");
                }}
            >
                {!ownersQueryEnabled ? (
                    <Select.Option value="disabled" disabled>
                        {enterQueryPrompt}
                    </Select.Option>
                ) : ownersQuery.isSuccess ? (
                    ownersQuery.data.map((owner: Owner) => {
                        const ownerName = `${owner.legal_name} | ${owner.state_code} | ${owner.whid}`;
                        return (
                            <Select.Option
                                key={owner.id}
                                value={owner.id}
                                title={ownerName}
                            >
                                {ownerName}
                            </Select.Option>
                        );
                    })
                ) : ownersQuery.isError ? (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading owner options
                    </Select.Option>
                ) : (
                    <Select.Option value="disabled" disabled>
                        Loading owners...
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default OwnersSelect;
