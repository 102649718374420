import { Form, Select } from "antd";

import { IndicatorIndustry } from "reactQuery/hooks/apiTypes";
import { useGetIndicatorIndustries } from "reactQuery/hooks/useIndicatorIndustries";

const IndustrySelect = ({}) => {
    const indicatorIndustriesQuery = useGetIndicatorIndustries();

    return (
        <Form.Item name="industry" label="Industry">
            <Select
                placeholder="Select an industry"
                showSearch
                allowClear={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                    const indicator = option.title.toLowerCase();
                    return indicator.includes(input.toLowerCase());
                }}
            >
                {indicatorIndustriesQuery.isLoading ? (
                    <Select.Option value="disabled" disabled>
                        Loading...
                    </Select.Option>
                ) : indicatorIndustriesQuery.isSuccess ? (
                    indicatorIndustriesQuery.data.map((industry: IndicatorIndustry) => (
                        <Select.Option
                            key={industry.id}
                            value={industry.id}
                            title={industry.value}
                        >
                            {industry.value}
                        </Select.Option>
                    ))
                ) : (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading industry options
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default IndustrySelect;
