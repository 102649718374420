import { Form, Select } from "antd";

import { TimingStatuses } from "reactQuery/hooks/apiTypes";
import { useGetAllTimingStatuses } from "reactQuery/hooks/useTimingStatuses";

const TimingStatusesSelect = ({
    constraintId,
    number,
}: {
    constraintId: number;
    number: number;
}) => {
    const timingStatusesQuery = useGetAllTimingStatuses();
    return (
        <Form.Item
            style={{ margin: 0 }}
            name={`constraint_${constraintId}_timing_statuses_${number}`}
        >
            <Select
                placeholder="Select one or more timing statuses"
                showSearch
                style={{ width: "100%" }}
                mode="multiple"
                allowClear={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                    const timingStatus = option.title.toLowerCase();
                    return timingStatus.includes(input.toLowerCase());
                }}
            >
                {timingStatusesQuery.isLoading ? (
                    <Select.Option value="disabled" disabled>
                        Loading...
                    </Select.Option>
                ) : timingStatusesQuery.isSuccess ? (
                    timingStatusesQuery.data.map((timingStatus: TimingStatuses) => {
                        return (
                            <Select.Option
                                key={timingStatus.id}
                                value={timingStatus.id}
                                title={timingStatus.status}
                            >
                                {timingStatus.status}
                            </Select.Option>
                        );
                    })
                ) : (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading timing status options
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default TimingStatusesSelect;
