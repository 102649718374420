import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, Collapse, Space, Row, Col, Spin } from "antd";
import { useEffect, useState } from "react";

import IndicatorSelect from "pages/SearchPlanForm/IndicatorSelect";
import IndustrySelect from "pages/SearchPlanForm/IndustrySelect";
import {
    Profile,
    SearchPlanFormIntelligenceScopeListItem,
} from "reactQuery/hooks/apiTypes";
import {
    useGetIntelligenceScopeDetail,
    usePatchIntelligenceScope,
} from "reactQuery/hooks/useSearchPlanForm";
import { searchPlanFormKeys } from "reactQuery/keys";
import { useSnackStore } from "stores/zustandStore";

import AdditionalNotesInput from "../AdditionalNotesInput";
import ConstraintRow from "../ConstraintRow";
import RankSelect from "../RankSelect";

const { Panel } = Collapse;

interface IntelligenceScopeFormProps {
    intelligenceScope: SearchPlanFormIntelligenceScopeListItem;
}

interface CommonConstraintProps {
    id: number;
    constraint_type?: string;
    filter_type?: string;
    profiles: Profile[];
}

const IntelligenceScopeForm = ({ intelligenceScope }: IntelligenceScopeFormProps) => {
    const [form] = Form.useForm();

    const formId = `intelligence-scopes-form-${intelligenceScope.id}`;

    const intelligenceScopeDetailQuery = useGetIntelligenceScopeDetail(
        intelligenceScope.id
    );

    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const [changedValues, setChangedValues] = useState({});
    const [nulledValues, setNulledValues] = useState([]);
    const valuesChanged = (changed: any) => {
        setChangedValues({ ...changedValues, ...changed });
    };

    useEffect(() => {
        const nullKeys = Object.keys(changedValues)
            .filter(
                (key) => changedValues[key] === null || changedValues[key] === undefined
            )
            .map((key) => key);
        setNulledValues(nullKeys);
    }, [changedValues]);

    const patchIntelligenceScope = usePatchIntelligenceScope(intelligenceScope.id);
    const queryClient = useQueryClient();

    const performMutate = () => {
        patchIntelligenceScope.mutate(
            { changedValues, nulledValues },
            {
                onSuccess: (response) => {
                    queryClient.setQueryData(
                        searchPlanFormKeys.intelligenceScopeDetail(
                            intelligenceScope.id
                        ),
                        response
                    );
                    setChangedValues({});
                    setNulledValues([]);
                    displaySuccessSnack({
                        message: "Form submitted successfull",
                    });
                },
                onError: () => {
                    displayErrorSnack({
                        message: "Error submitting form",
                    });
                },
            }
        );
    };

    const submitForm = () => {
        form.validateFields()
            .then((_) => {
                performMutate();
            })
            .catch((errorInfo) => {
                displayErrorSnack({
                    message: errorInfo,
                });
            });
    };

    const intelligenceScopeDetail = intelligenceScopeDetailQuery.data;
    useEffect(() => {
        if (intelligenceScopeDetailQuery.isSuccess) {
            form.setFieldsValue({
                industry: intelligenceScopeDetail?.industry,
                indicator: intelligenceScopeDetail?.indicator,
                rank: intelligenceScopeDetail?.rank,
                additional_notes: intelligenceScopeDetail?.additional_notes,
            });

            const {
                country_constraints: countryConstraints,
                state_constraints: stateConstraints,
                county_constraints: countyConstraints,
                owner_constraints: ownerConstraints,
                owner_type_constraints: ownerTypeConstraints,
                population_constraints: populationConstraints,
                initiativetype_constraints: initiativeTypeConstraints,
                timing_status_constraints: timingStatusConstraints,
                indicatortag_constraints: indicatorTagConstraints,
                project_budget_constraints: projectBudgetConstraints,
                source_timeframe_constraints: sourceTimeframeConstraints,
                mgd_constraints: mgdConstraints,
                display_start_date_constraints: displayStartDateConstraints,
            } = intelligenceScopeDetail;

            [
                countryConstraints,
                stateConstraints,
                countyConstraints,
                ownerConstraints,
                ownerTypeConstraints,
                populationConstraints,
                initiativeTypeConstraints,
                timingStatusConstraints,
                indicatorTagConstraints,
                projectBudgetConstraints,
                sourceTimeframeConstraints,
                mgdConstraints,
                displayStartDateConstraints,
            ].forEach((constraints) => {
                constraints.forEach(
                    (
                        {
                            id,
                            constraint_type,
                            filter_type,
                            profiles,
                        }: CommonConstraintProps,
                        index: number
                    ) => {
                        const number = index + 1;
                        form.setFieldsValue({
                            [`constraint_${id}_requirement_type_${number}`]:
                                constraint_type ?? "Include",
                            [`constraint_${id}_filter_type_${number}`]: filter_type,
                            [`constraint_${id}_profiles_${number}`]: profiles.map(
                                (profile) => ({
                                    value: profile.id,
                                    label: `${profile.user.first_name} ${profile.user.last_name} (${profile.team.name})`,
                                })
                            ),
                        });
                    }
                );
            });

            countryConstraints.forEach(({ id, countries }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_countries_${number}`]: countries.map(
                        (country) => country.code
                    ),
                });
            });
            stateConstraints.forEach(({ id, states }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_states_${number}`]: states.map(
                        (state) => state.code
                    ),
                });
            });
            countyConstraints.forEach(({ id, demographics }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_counties_${number}`]: demographics.map(
                        (demographic) => ({
                            value: demographic.id,
                            label: `${demographic.name} (${demographic.state_code})`,
                        })
                    ),
                });
            });
            ownerConstraints.forEach(({ id, owners }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_owners_${number}`]: owners.map((owner) => ({
                        value: owner.id,
                        label: `${owner.legal_name} | ${owner.state_code} | ${owner.whid}`,
                    })),
                });
            });
            ownerTypeConstraints.forEach(({ id, owner_types }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_owner_types_${number}`]: owner_types.map(
                        (ownerType) => ({
                            value: ownerType.id,
                            label: ownerType.owner_type,
                        })
                    ),
                });
            });
            populationConstraints.forEach(({ id, minimum, maximum }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_population_minimum_${number}`]: minimum,
                    [`constraint_${id}_population_maximum_${number}`]: maximum,
                });
            });
            initiativeTypeConstraints.forEach(
                ({ id, initiative_types }, index: number) => {
                    const number = index + 1;
                    form.setFieldsValue({
                        [`constraint_${id}_initiative_types_${number}`]:
                            initiative_types.map((initiativeType) => initiativeType.id),
                    });
                }
            );
            timingStatusConstraints.forEach(({ id, statuses }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_timing_statuses_${number}`]: statuses.map(
                        (timingStatus) => timingStatus.id
                    ),
                });
            });
            indicatorTagConstraints.forEach(({ id, indicator_tags }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_indicator_tags_${number}`]: indicator_tags.map(
                        (indicatorTag) => ({
                            value: indicatorTag.id,
                            label: indicatorTag.value,
                        })
                    ),
                });
            });
            projectBudgetConstraints.forEach(
                ({ id, minimum, maximum }, index: number) => {
                    const number = index + 1;
                    form.setFieldsValue({
                        [`constraint_${id}_budget_minimum_${number}`]: minimum,
                        [`constraint_${id}_budget_maximum_${number}`]: maximum,
                    });
                }
            );
            sourceTimeframeConstraints.forEach(({ id, maximum }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_maximum_months_${number}`]: maximum,
                });
            });
            mgdConstraints.forEach(({ id, minimum, maximum }, index: number) => {
                const number = index + 1;
                form.setFieldsValue({
                    [`constraint_${id}_minimum_mgd_${number}`]: minimum,
                    [`constraint_${id}_maximum_mgd_${number}`]: maximum,
                });
            });
            displayStartDateConstraints.forEach(
                ({ id, display_start_date }, index: number) => {
                    const number = index + 1;
                    form.setFieldsValue({
                        [`constraint_${id}_display_start_date_${number}`]:
                            display_start_date,
                    });
                }
            );
        }
    }, [intelligenceScopeDetailQuery.isSuccess]);

    if (intelligenceScopeDetailQuery.isLoading) {
        return <Spin />;
    }

    return (
        <Form
            form={form}
            id={formId}
            name={formId}
            onFinish={submitForm}
            onValuesChange={valuesChanged}
            layout="vertical"
        >
            <Row gutter={16}>
                <Col span={6}>
                    <IndustrySelect />
                </Col>

                <Col span={6}>
                    <IndicatorSelect />
                </Col>

                <Col span={6}>
                    <RankSelect />
                </Col>

                <Col span={6}>
                    <AdditionalNotesInput />
                </Col>
            </Row>

            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <Collapse>
                    <Panel header="Filters" key="filters">
                        <Space
                            direction="vertical"
                            size="large"
                            style={{ width: "100%" }}
                        >
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="countries"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="states"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="counties"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="owners"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="owner_types"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="population"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="initiative_types"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="timing_status"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="indicator_tags"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="project_budget"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="source_document_date"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="design_flow_mgd"
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintType="display_start_date"
                            />
                        </Space>
                    </Panel>
                </Collapse>

                <Button key="link" type="primary" htmlType="submit" form={formId}>
                    Submit
                </Button>
            </Space>
        </Form>
    );
};

export default IntelligenceScopeForm;
