import { Form, Select } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useSearchDebounced } from "components/helpers/debounce";
import { Profile } from "reactQuery/hooks/apiTypes";
import { useGetSearchPlanOverview } from "reactQuery/hooks/useSearchPlanForm";
import { useGetUsersByQuery } from "reactQuery/hooks/useUsers";

import { RecordProps } from "../columnInfo";

const ProfilesSelect = ({ constraintId, number }: RecordProps) => {
    const [searchQuery, setSearchQuery] = useState("");
    const queryMinChars = 1;
    const enterQueryPrompt = `Enter at least ${queryMinChars} characters to search for a user`;

    const { search_plan_id: searchPlanId } = useParams();
    const searchPlanOverviewQuery = useGetSearchPlanOverview(searchPlanId);
    const teamId = searchPlanOverviewQuery.data.team.id;

    const userProfilesQueryEnabled = searchQuery.length >= queryMinChars;
    const userProfilesQuery = useGetUsersByQuery(
        teamId,
        searchQuery,
        userProfilesQueryEnabled && searchPlanOverviewQuery.isSuccess
    );
    const debounceSearch = useSearchDebounced(setSearchQuery);

    return (
        <Form.Item
            style={{ margin: 0 }}
            name={`constraint_${constraintId}_profiles_${number}`}
        >
            <Select
                placeholder="Select one or more users"
                showSearch
                filterOption={false}
                allowClear={true}
                mode="multiple"
                style={{ width: "100%" }}
                onSearch={(value) => {
                    debounceSearch(value);
                }}
                onClear={() => {
                    debounceSearch("");
                }}
            >
                {!userProfilesQueryEnabled ? (
                    <Select.Option value="disabled" disabled>
                        {enterQueryPrompt}
                    </Select.Option>
                ) : userProfilesQuery.isSuccess ? (
                    (userProfilesQuery.data || []).map((profile: Profile) => {
                        const fullUserNameWithTeam = `${profile.user.first_name} ${profile.user.last_name} (${profile.team.name})`;

                        return (
                            <Select.Option
                                key={profile.id}
                                value={profile.id}
                                title={fullUserNameWithTeam}
                            >
                                {fullUserNameWithTeam}
                            </Select.Option>
                        );
                    })
                ) : userProfilesQuery.isError ? (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading user options
                    </Select.Option>
                ) : (
                    <Select.Option value="disabled" disabled>
                        Loading users...
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default ProfilesSelect;
