import { Select, Switch, Checkbox, Input, Button, Form } from "antd";

import CountiesSelect from "./CountiesSelect";
import CountriesSelect from "./CountriesSelect";
import IndicatorTagsSelect from "./IndicatorTagsSelect";
import InitiativeTypesSelect from "./InitiativeTypesSelect";
import OwnersSelect from "./OwnersSelect";
import OwnerTypesSelect from "./OwnerTypesSelect";
import ProfilesSelect from "./ProfilesSelect";
import StatesSelect from "./StatesSelect";
import TimingStatusesSelect from "./TimingStatusesSelect";

export interface RecordProps {
    key: number;
    number: number;
    constraintId: number;
}

interface ConstraintColumnProps {
    title: string;
    dataIndex: string;
    key: string;
    render: (text: any, record: RecordProps) => JSX.Element;
    width?: string;
}

export const numberColumn = {
    title: "#",
    dataIndex: "number",
    key: "number",
    width: "5%",
};

export const countriesColumn: ConstraintColumnProps = {
    title: "Countries",
    dataIndex: "countries",
    key: "countries",
    render: (_, record) => {
        return <CountriesSelect {...record} />;
    },
    width: "20%",
};

export const statesColumn: ConstraintColumnProps = {
    title: "States",
    dataIndex: "states",
    key: "states",
    render: (_, record) => {
        return <StatesSelect {...record} />;
    },
    width: "20%",
};

export const countiesColumn: ConstraintColumnProps = {
    title: "Counties",
    dataIndex: "counties",
    key: "counties",
    render: (_, record) => {
        return <CountiesSelect {...record} />;
    },
    width: "20%",
};

export const ownersColumn: ConstraintColumnProps = {
    title: "Owners",
    dataIndex: "owners",
    key: "owners",
    render: (_, record) => {
        return <OwnersSelect {...record} />;
    },
    width: "20%",
};

export const ownerTypesColumn: ConstraintColumnProps = {
    title: "Owner Types",
    dataIndex: "ownerTypes",
    key: "ownerTypes",
    render: (_, record) => {
        return <OwnerTypesSelect {...record} />;
    },
    width: "20%",
};

export const populationMinimumColumn: ConstraintColumnProps = {
    title: "Minimum",
    dataIndex: "populationMinimum",
    key: "populationMinimum",
    render: (_, { constraintId, number }) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${constraintId}_population_minimum_${number}`}
            >
                <Input type="number" />
            </Form.Item>
        );
    },
    width: "10%",
};

export const populationMaximumColumn: ConstraintColumnProps = {
    title: "Maximum",
    dataIndex: "populationMaximum",
    key: "populationMaximum",
    render: (_, { constraintId, number }) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${constraintId}_population_maximum_${number}`}
            >
                <Input type="number" />
            </Form.Item>
        );
    },
    width: "10%",
};

export const initiativeTypesColumn: ConstraintColumnProps = {
    title: "Initiative Types",
    dataIndex: "initiativeTypes",
    key: "initiativeTypes",
    render: (_, record) => {
        return <InitiativeTypesSelect {...record} />;
    },
    width: "20%",
};

export const timingStatusesColumn: ConstraintColumnProps = {
    title: "Timing Statuses",
    dataIndex: "timingStatus",
    key: "timingStatus",
    render: (_, record) => {
        return <TimingStatusesSelect {...record} />;
    },
    width: "20%",
};

export const indicatorTagsColumn: ConstraintColumnProps = {
    title: "Indicator Tags",
    dataIndex: "indicatorTags",
    key: "indicatorTags",
    render: (_, record) => {
        return <IndicatorTagsSelect {...record} />;
    },
    width: "20%",
};

export const budgetMinimumColumn: ConstraintColumnProps = {
    title: "Minimum $",
    dataIndex: "budgetMinimum",
    key: "budgetMinimum",
    render: (_, { constraintId, number }) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${constraintId}_budget_minimum_${number}`}
            >
                <Input type="number" />
            </Form.Item>
        );
    },
    width: "10%",
};

export const budgetMaximumColumn: ConstraintColumnProps = {
    title: "Maximum $",
    dataIndex: "budgetMaximum",
    key: "budgetMaximum",
    render: (_, { constraintId, number }) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${constraintId}_budget_maximum_${number}`}
            >
                <Input type="number" />
            </Form.Item>
        );
    },
    width: "10%",
};

export const maximumMonthsColumn: ConstraintColumnProps = {
    title: "Maximum Months",
    dataIndex: "maximumMonths",
    key: "maximumMonths",
    render: (_, { constraintId, number }) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${constraintId}_maximum_months_${number}`}
            >
                <Input type="number" />
            </Form.Item>
        );
    },
    width: "20%",
};

export const minimumMGDColumn: ConstraintColumnProps = {
    title: "Minimum MGD",
    dataIndex: "minimumMGD",
    key: "minimumMGD",
    render: (_, { constraintId, number }) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${constraintId}_minimum_mgd_${number}`}
            >
                <Input type="number" />
            </Form.Item>
        );
    },
    width: "10%",
};

export const maximumMGDColumn: ConstraintColumnProps = {
    title: "Maximum MGD",
    dataIndex: "maximumMGD",
    key: "maximumMGD",
    render: (_, { constraintId, number }) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${constraintId}_maximum_mgd_${number}`}
            >
                <Input type="number" />
            </Form.Item>
        );
    },
    width: "10%",
};

export const startDisplayDateColumn: ConstraintColumnProps = {
    title: "Start Display Date",
    dataIndex: "startDisplayDate",
    key: "startDisplayDate",
    render: (_, { constraintId, number }) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${constraintId}_display_start_date_${number}`}
            >
                <Input type="date" />
            </Form.Item>
        );
    },
    width: "20%",
};

export const requirementTypeColumn: ConstraintColumnProps = {
    title: "Requirement Type",
    dataIndex: "requirementType",
    key: "requirementType",
    render: (_, record) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${record.constraintId}_requirement_type_${record.number}`}
            >
                <Select
                    style={{ width: "100%" }}
                    options={[
                        { value: "Include", label: "Include" },
                        { value: "Exclude", label: "Exclude" },
                    ]}
                />
            </Form.Item>
        );
    },
    width: "10%",
};

export const filterTypeColumn: ConstraintColumnProps = {
    title: "Filter Type",
    dataIndex: "filterType",
    key: "filterType",
    render: (_, record) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={`constraint_${record.constraintId}_filter_type_${record.number}`}
            >
                <Select
                    style={{ width: "100%" }}
                    options={[
                        { value: "Requirement", label: "Requirement" },
                        { value: "Priority - High", label: "Priority - High" },
                        { value: "Priority - Medium", label: "Priority - Medium" },
                        { value: "Priority - Low", label: "Priority - Low" },
                    ]}
                />
            </Form.Item>
        );
    },
    width: "10%",
};

export const profilesColumn: ConstraintColumnProps = {
    title: "Users",
    dataIndex: "users",
    key: "users",
    render: (_, record) => {
        return <ProfilesSelect {...record} />;
    },
};

export const nullColumn = {
    title: "Include Null",
    dataIndex: "null",
    key: "null",
    render: (text, record) => {
        return <Checkbox />;
    },
    width: "5%",
};

export const copyColumn = {
    title: "Copy",
    dataIndex: "copy",
    key: "copy",
    render: (text, record) => {
        return <Switch />;
    },
    width: "5%",
};

export const deleteColumn = {
    title: "Delete",
    dataIndex: "delete",
    key: "delete",
    render: (text, record) => {
        return (
            <Button size="small" danger>
                Delete
            </Button>
        );
    },
    width: "5%",
};
